//SOP-108 Included by PCS

.rectangle-switch {
                width: 48px;
                height: 24px;
                padding: 0;
                border-radius: 4px;
                display: inline-flex;
                align-items: center;
                background-color: #ccc;
                cursor: pointer;
                position: relative;
                transition: background-color 0.3s;
}
              
              .rectangle-switch.checked {
                background-color: #3f51b5;
              }
              
              .rectangle-switch .switch-thumb {
                width: 20px;
                height: 20px;
                background-color: #fff;
                border-radius: 2px;
                position: absolute;
                transition: transform 0.3s;
              }
              
              .rectangle-switch.checked .switch-thumb {
                transform: translateX(24px);
              }

//EOF SOP-108 Included by PCS